@import "../css/variables.module";

.excalidraw {
  .TextInput {
    color: var(--text-color-primary);
    display: inline-block;
    border: 1.5px solid var(--button-gray-1);
    line-height: 1;
    padding: 0.75rem;
    white-space: nowrap;
    border-radius: var(--space-factor);
    background-color: var(--input-background-color);

    &:not(:focus) {
      &:hover {
        background-color: var(--input-hover-background-color);
      }
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--focus-highlight-color);
    }
  }
}
