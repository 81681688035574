.excalidraw {
  .popover {
    position: absolute;
    z-index: 10;
  }

  .popover .cover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
